.nav-colour {
    background-color:#C4C4C4;
    color: #fff;
    padding-top:0px;
    padding-bottom:0px;
}

.navbar > .container {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  

.headerContainer {
    margin:40px;
    padding: 0px;
    height:124px;
}