.containerChart {
    text-align : left;
    margin: 30px 40px 0px 40px;
    position: relative;
    width: calc(100% - 80px);
    z-index: 0;
    border: 1px solid black;
    border-radius: 5px;
}

.chartHeader {
    height: 57px;
    font-size: 14px;
    border-bottom: 1px solid black;
    display: flex;
    position: relative;
}

.chartTitle {
    display:flex;
    width: 100%;
}

.chartLength{
    width:100%;
    display:flex;
}

.lengthDescription {
    position: relative;
    text-align : right;
    margin: 3px 10px 0px;
}

.chartLengthSelect{
    width: 160px;
    height: 32px;
}


.box {
    width:100%;
    display:flex
}

.legend {
    width:20%;
    border-right: 1px solid black;
    padding: 40px 0px 0px 40px;;
}

.marker {
    margin-top:23px;
}

.outer-circle {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: relative;
    margin-top:3px;
  }

.inner-circle {
    position: absolute;
    background: #fff;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    top: 50%;
    left: 50%;
    margin: -5px 0px 0px -5px;
}

.active {
    visibility:hidden;
}

.plotArea {
    background-color: white;
    width: 80%;
    margin : auto;
    padding : 10px;
    border-width: 10px;
    border-color: black;
    border-radius: 10px;
    
}

.chart {
    height: 500px;
}