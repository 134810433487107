.searchContainer {
    width: 100%;
    display: flex;
    overflow:visible;
    height:72px;
    position: relative;
    z-index: 2;
}

.search {
    width : 160px;
    margin: 40px 20px 0px 40px;
    font-size: 14px;
}
.mb-3 {
    margin-bottom: 0!important;
}

.searchBar {
    width : 117px;
    height : 32px;
    border-radius: 5px;
    border-color: black;
}

.searchButton {
    height : 32px;
    border-radius: 5px;
    padding-top: 3px;
}

.suggestions {
    text-align: left;
    font-size: x-small;
    list-style-type:none;
    background-color: #fff;
    margin-left: 40px;
    width : 129px;
    padding-left : 0px;
}

.suggestions > li {
  border-bottom: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  z-index: 9;
  padding-left : 10px;
  background-color: #fff;
}

.hidden {
    display: none;
}

.assetSelect, .frequencySelect{
    width : 160px;
    height : 32px;
    margin : 40px 20px 0px 0px;
    border-color: black;
}