.statsContainer {
    width: calc(100% - 80px);
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    margin-left: 40px;
    margin-top: 40px;
}

.statsBox {
    width: 15%;
    font-size:14px;
}

.statsTitle {
    margin: auto;
    border-bottom: 1px solid black;
    height: 57px;
    position: relative;
}

.statsData {
    position: relative;
    height: 57px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
  }

  .left {
    margin-left: 20px;
    text-align: left;
}
.right {
    text-align: right;
    margin-right: 20px;
}
.left, .right {
    display: inline-block;
    width: 50%;
}

.direction {
    width: 10px;
}