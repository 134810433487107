.footer {
    background-color: #fff;
    padding: 8px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    text-align: center;
    margin-top: 20px;
}